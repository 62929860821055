var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-row',{staticClass:"p-1",attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('h3',{staticClass:"m-0"},[_vm._v(" Tipos de empresa ")])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.modalVisible = true}}},[_vm._v(" "+_vm._s("+ Añadir nuevo tipo de empresa")+" ")])],1)],1),_c('b-table',{attrs:{"hover":"","responsive":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var value = ref.value;
var index = ref.index;
return [_c('b-form-input',{attrs:{"value":value},on:{"change":function($event){return _vm.handleInputNameChange($event, value, index)}}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"text-danger",on:{"click":function($event){return _vm.handleDeleteIconClick(item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"20"}})],1)]}}])})],1)],1)],1),_c('BaseModal',{attrs:{"title":"Nuevo tipo de empresa","size":"md"},on:{"confirm-button-click":_vm.handleConfirmButtonClick},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c('validation-observer',{ref:"company-type-form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre","label-for":"company-type-name"}},[_c('b-form-input',{attrs:{"id":"company-type-name"},model:{value:(_vm.newCompanyType),callback:function ($$v) {_vm.newCompanyType=$$v},expression:"newCompanyType"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }