<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
            align-h="between"
            align-v="center"
            class="p-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                Tipos de empresa
              </h3>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="primary"
                @click="modalVisible = true"
              >
                {{ "+ Añadir nuevo tipo de empresa" }}
              </b-button>
            </b-col>
          </b-row>
          <b-table
            hover
            responsive
            :items="items"
            :fields="fields"
          >
            <template #cell(name)="{ value, index }">
              <b-form-input
                :value="value"
                @change="handleInputNameChange($event, value, index)"
              />
            </template>
            <template #cell(actions)="{ item }">
              <b-link
                class="text-danger"
                @click="handleDeleteIconClick(item)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="20"
                />
              </b-link>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <BaseModal
      v-model="modalVisible"
      title="Nuevo tipo de empresa"
      size="md"
      @confirm-button-click="handleConfirmButtonClick"
    >
      <validation-observer ref="company-type-form">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-group
                  label="Nombre"
                  label-for="company-type-name"
                >
                  <b-form-input
                    id="company-type-name"
                    v-model="newCompanyType"
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseModal from '@/components/ui/modal/BaseModal.vue'

export default {
  name: 'List',
  components: { BaseModal },
  data() {
    return {
      modalVisible: false,
      newCompanyType: null,
      fields: [
        {
          label: 'Nombre',
          key: 'name',
          sortable: true,
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      companyTypes: 'config/getSelectTypeCompany',
    }),
    items() {
      return this.companyTypes.map(companyType => ({ name: companyType }))
    },
  },
  mounted() {
    this.getSelectTypeCompany()
  },
  methods: {
    ...mapActions('config', ['getSelectTypeCompany', 'editSelectTypeCompany', 'setSelectTypeCompany']),
    handleInputNameChange(event, companyTypeName, index) {
      const newCompanyTypes = [...this.companyTypes]
      newCompanyTypes[index] = companyTypeName
      this.setSelectTypeCompany(newCompanyTypes)
      this.editSelectTypeCompany(newCompanyTypes)
    },
    async handleDeleteIconClick(companyType) {
      const confirmation = await this.$bvModal.msgBoxConfirm(`¿Estás seguro de eliminar ${companyType.name}?`, {
        bodyClass: 'bodyDelete',
        footerClass: 'footerDelete',
        okVariant: 'danger',
        okTitle: 'Eliminar',
        cancelTitle: 'Cancelar',
        cancelVariant: 'outline-danger',
        centered: true,
      })

      if (!confirmation) {
        return
      }

      this.setSelectTypeCompany(this.companyTypes.filter(item => item !== companyType.name))
      this.editSelectTypeCompany(this.companyTypes)
    },
    async handleConfirmButtonClick() {
      const valid = await this.$refs['company-type-form'].validate()
      if (!valid) {
        return
      }

      const newCompanyTypes = [...this.companyTypes]
      newCompanyTypes.push(this.newCompanyType)

      this.modalVisible = false
      await this.editSelectTypeCompany(newCompanyTypes)
      this.setSelectTypeCompany(newCompanyTypes)
      this.newCompanyType = null
    },
  },
}
</script>

<style scoped>
</style>
